import React from "react"
import "./index.css"
import { Container, Row, Col, Spinner } from "react-bootstrap"
import "aos/dist/aos.css"
import FirebaseApp from "./../../config/firebase"
import { navigate } from "gatsby" //import navigate from gatsby
import SendEmailLogo from "./../../images/message.png"
let database = FirebaseApp.database().ref("/")

export default class emailVerification extends React.Component {
  constructor() {
    super()
    this.state = {
      error: "",
      emailVerified: false,
      sucessMessage: "",
    }
  }
  checkVerification = () => {
    FirebaseApp.auth().onAuthStateChanged(user => {
      if (user && user.emailVerified) {
        database.child("Registration/").on("child_added", res => {
          navigate("/courses") //navigate to edit page
        })
      }
    })
  }
  //LIFECYCLE (this perform when app load first time)
  componentDidMount() {
    this.checkVerification()
  }
  // SEND VERIFICATION MAIL AGAIN
  _sendMailAgain = () => {
    FirebaseApp.auth()
      .currentUser.sendEmailVerification()
      .then(() => {
        this.setState({ sucessMessage: "Email sent successfully" })
      })
      .catch(err => {})
  }

  refreshPage = () => {
    window.location.reload(false)
    FirebaseApp.auth().onAuthStateChanged(user => {
      if (user && user.emailVerified) {
        this.setState({ emailVerified: user.emailVerified })
        navigate("/courses")
      } else {
        navigate("/emailVerification")
      }
    })
  }

  render() {
    return (
      <div className="_emailverificationDiv">
        <Container>
          <Row className="justify-content-center">
            <Col lg="8">
              {this.state.emailVerified ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                  style={{ color: "purple" }}
                />
              ) : (
                <div className="emailVerificationDiv">
                  <div className="_sendEmailDiv">
                    <img src={SendEmailLogo} className="_sendEmailLogo" />
                  </div>
                  <p className="_emailTitle">Verify your email address</p>
                  <p className="_emailPara">
                    Please confirm that you want to use this as your iHunar
                    account email address. Onces it's done you will be able to
                    go ahead.
                  </p>
                  <button className="_emailBtn" onClick={this.refreshPage}>
                    Go to Home Page
                  </button>
                  <p className="_verifyEmailError">{this.state.error}</p>
                  <p
                    style={{
                      fontSize: 12,
                      color: "green",
                    }}
                  >
                    {this.state.sucessMessage}
                  </p>
                  <p
                    style={{
                      cursor: "pointer",
                      fontSize: 13,
                      color: "dodgerblue",
                    }}
                    onClick={() => this._sendMailAgain()}
                  >
                    Resend Email
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
